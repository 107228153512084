var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-4"},[_c('cropper',{class:{ 'upload-example-cropper': true },attrs:{"stencil-props":_vm.stencilProps,"src":_vm.thumbnail,"canvas":false,"check-orientation":false},on:{"change":_vm.onChangeCropper}})],1),_c('div',{staticClass:"wa__modal__form"},[_c('div',[(_vm.hiddenInput)?_c('block-button',{staticClass:"w-100",attrs:{"text":"select your file","icon":"WMi-camera","height":"30","bg-color":"black","color":"white"},nativeOn:{"click":function($event){return _vm.$refs.fileInput.$refs.input.click()}}}):_vm._e(),(_vm.rules[0] &&( typeof  _vm.rules[0]() !== 'boolean' ))?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.rules[0]()))]):_vm._e(),_c('v-file-input',{ref:"fileInput",style:([
          _vm.hiddenInput
            ? {
                visibility: 'hidden',
                height: '0',
                padding: '0',
              }
            : '',
        ]),attrs:{"accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-camera","label":_vm.label,"rules":_vm.rules},on:{"change":_vm.changeImage}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }