<template>
  <div>
    <div class="my-4">
      <cropper
        :class="{ 'upload-example-cropper': true }"
        :stencil-props="stencilProps"
        @change="onChangeCropper"
        :src="thumbnail"
        :canvas="false"
        :check-orientation="false"
      />
      <!--            <img :src="thumbnail"  alt="new brand image"/>-->
    </div>
    <div class="wa__modal__form">
      <div>
        <block-button
          text="select your file"
          icon="WMi-camera"
          class="w-100"
          height="30"
          bg-color="black"
          color="white"
          v-if="hiddenInput"
          @click.native="$refs.fileInput.$refs.input.click()"
        />
        <span class="text-danger" v-if="rules[0] &&( typeof  rules[0]() !== 'boolean' )">{{rules[0]()}}</span>
        <v-file-input
          accept="image/png, image/jpeg, image/bmp"
          prepend-icon="mdi-camera"
          :label="label"
          @change="changeImage"
          :rules="rules"
          ref="fileInput"
          :style="[
            hiddenInput
              ? {
                  visibility: 'hidden',
                  height: '0',
                  padding: '0',
                }
              : '',
          ]"
        ></v-file-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import DefaultImage from "../../../assets/1100x1300.png";
import "vue-advanced-cropper/dist/style.css";
import BlockButton from "../Button/BlockButton.vue";
export default {
  components: {
    Cropper,
    BlockButton,
  },
  props: {
    url: {
      default: DefaultImage,
    },
    value: {
      default: () => ({}),
    },
    stencilProps: {
      default: () => ({ aspectRatio: 367 / 434, checkImageOrigin: false }),
    },
    crop: {
      default: () => [],
    },
    label: {
      default: "",
    },
    rules: {
      default: () => [(v) => !!v || true],
    },
    hiddenInput: {
      default: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    thumbnail: {
      get() {
        return this.url;
      },
      set(value) {
        this.$emit("update:url", value);
      },
    },
  },
  methods: {
    async changeImage(file) {
      if (file) {
        this.form = { ...this.value, ...{ file } };
        const reader = new FileReader();
        reader.onload = (file) => {
          this.thumbnail = file.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    onChangeCropper({ coordinates }) {
      this.form = { ...this.value, ...{ crop: coordinates } };
    },
  },
};
</script>
<style scoped>
/* dialog  */
.wa__modal__image {
  width: 250px;
  margin: 0 auto;
  border: 1px dashed var(--color-gray);
}
.upload-example-cropper {
  border: 1px solid #afafaf;
  border-radius: 5px;
  background-color: white !important;
  height: 300px;
}
.upload-example-cropper.red {
  border: 1px solid red;
}
</style>
